import React from 'react'
import uuid from 'uuid'

export const Prices = (props) => {
    return (
        <div className='price_element'>
            <div className={`prices_component--${props.package.toLowerCase()}`}>
                <h1>{props.package}</h1>
                <h2>{props.price}</h2>
                {props.subscription && <h3>{props.subscription}</h3>}
                <h3>{props.description}</h3>
                {
                    props.services && 
                    <ul>
                        {
                            props.services.map((service) => {
                            return (
                                <li key={uuid()}>{service}</li>
                            )})
                        }
                    </ul>
                }
            </div>
        </div>
    )
}