export const content = {
    services1: {
        title: 'Brand Development',
        description: 'Our design team will update and enhance your logo and brand, forging a notable new identity.',
        url: 'https://i.imgur.com/0E1vaTG.png',
    },
    services2: {
        title: 'Graphic Design',
        description: 'All of your design needs will be met whether you need physical or digital messaging.',
        url: 'https://i.imgur.com/XnJmczc.png',
    },
    services3: {
        title: 'Search Engine Optimization',
        description: 'Expert and advanced SEO techniques will get you to the top search result',
        url: 'https://i.imgur.com/jSAa0I7.png',
    },
    services4: {
        title: 'Web Development',
        description: 'We build and redesign completely custom websites so your customers are never waiting.',
        url:'https://i.imgur.com/3mbp8em.png',
    },
    services5: {
        title: 'Social Media',
        description: 'Let us help you grow your business’s or personal brand with organic social media outreach.',
        url: 'https://i.imgur.com/4AVn7dc.png'
    },
    services6: {
        title: 'Digital Distribution',
        description: 'CMS helps your paid social advertisements reach your target audience for significantly less than you pay now.',
        url: 'https://i.imgur.com/ghzTXqL.png'
    },
    accountManagementText: 'Our dedicated, professional team will assign you a manager who will always be making sure your specific account is running perfectly. They will be there to answer any questions you have and help however is needed.',
    fullWebsiteAccessText: "With our instructional videos, you'll be able to edit your text whenever you need! On the go and need to update your phone number or hours? No Worries! This feature guarentees you're able to do it lightning fast.",
    DandCService1: {
        img: 'https://i.imgur.com/g8Yl3Dg.png',
        textEmphasis: 'Expert Design',
        textBody2: ' based on brand expression'
    },
    DandCService2: {
        img: 'https://i.imgur.com/oIdvP8K.png',
        textBody1: 'Ongoing mobile site and website ',
        textEmphasis: 'enhancements'
    },
    DandCService3: {
        img: 'https://i.imgur.com/EoITMSp.png',
        textBody1: 'Constant ',
        textEmphasis: 'data reports ',
        textBody2: 'for quick decision making'
    },
    DandCService4: {
        img: 'https://i.imgur.com/ps8SHCN.png',
        textEmphasis: 'Enhanced security ',
        textBody2: 'to prevent hacking'
    },
    DigitalMarketingService1: {
        src: 'https://i.imgur.com/YaM56rE.png',
        text: 'Automated Google PPC campaigns to reach customers quickly'
    },
    DigitalMarketingService2: {
        src: 'https://i.imgur.com/n08dV2Q.png', 
        text: 'Facebook Ads targeting to reach the most customers'
    },
    DigitalMarketingService3: {
        src: 'https://i.imgur.com/Zk5iACT.png',
        text: 'Digital Footprint Analysis to examine brand weaknesses'
    },
    DigitalMarketingService4: {
        src: 'https://i.imgur.com/X0WjeUr.png',
        text: 'White-Labeled Solutions to grow your online presence'
    },
    PricesWebDesign: {
        basic: {
            package: 'Starter',
            price: '$1250',
            subscription: '$175/month',
            description: 'Every website needs to start somewhere! We make it affordable and easy to have a professionally designed website!',
            services: [
                '100 hrs of Website Design and Creation',
                'Premium, Secure Hosting',
                'Monthly Website Improvements',
                'Mobile-Friendly Interface',
                'SEO Keyword Marketing',
                'Weekly Website Audits',
                'Competitor Website Audits',
                '24/7 On-Call Security and Customer Service',
                '1 on 1 Account Manager',
                'Custom Email Setup',
                'Legal Templates'
            ]
        },
        intermediate: {
            package: 'Basic',
            price: '$2500',
            subscription: '$300/month',
            description: 'For local businesses wanting to expand their footprint in Chattanooga, this package is for you!',
            services: [
                'Everything within the Web & SEO Package',
                'Weekly Website Improvements',
                'Gatsby SEO Technical Enhancements',
                '5 hours of Weekly SEO Keyword Optimization',
                'Competitor SEO Audits',
                'Consistent Progress Reports',
                'Full Google Business Optimization',
                'Self-Help SEO Videos',
                'Local Traffic-Driven Digital Distribution',
                'Monthly 1 on 1 meetings with our expert design team',
                'Press Releases and Media Inquiry Management'
            ]
        },
        premium: {
            package: 'Premium',
            price: '$8,000',
            subscription: '$850/month',
            description: 'Looking to bring your groundbreaking idea worldwide? Look no further, this is our bread and butter!',
            services: [
                'Everything in the Local package',
                '20 hours of Weekly SEO Keyword Optimization',
                'Backlink Building',
                'SEO-Centered Blog Posts',
                'Social Media Set-Ups',
                'Social Media Audits',
                '4 Long-Form Social Media Posts',
                'Copy Creation',
                'Media Outreach',
                'Unlimited Page Design',
                'Unlimited Meetings With Designers',
                'Unlimited Revisions',
            ]
        }
    },
    SEO: {
        basic: {
            package: 'Starter',
            price: '$275/Month',
            description: 'For those wanting to enhance their SEO results at the neighborhood level.',
            services: [
                'Up to 100 keywords and phrases optimized',
                'Quarterly Competitor Audits',
                'Content Development Plan (5 Monthly Posts)',
                'Monthly Optimization Reporting',
                'Google Business Account Management',
                'Personal Account Manager'
            ]
        },
        intermediate: {
            package: 'Basic',
            price: '$500/Month',
            description: 'As industry leaders, you need to stay one step ahead. This plan guarantees you will be, focusing on the local city level of SEO.',
            services: [
                'Everything from the Starter Package',
                'Up to 200 Keywords and Phrases Optimized',
                'Primary Focus on Local Growth',
                'Strategic SEO Plan',
                'Monthly Competitor Audits',
                'Content Development Plan and Implementation (15 monthly posts)',
                'Weekly Optimization Reporting',
                'Monthly Strategy Report From Your Account Manager'
            ]
        },
        premium: {
            package: 'Premium',
            price: '$1250/Month',
            description: "While you spend your time innovating and trailblazing, we make sure your website is in front of your competitors'. This tier is for companies looking to grow their national footprint.",
            services: [
                'Everything from the Basic Package',
                'Up to 300 Keywords and phrases optimized',
                'Focus on National Growth',
                'Weekly Competitor Audits',
                'Content Development and Copywriting (20 Monthly Posts)',
                'Content Distribution from our network',
                'Access to our mentor-network',
                'Daily Optimization Reporting and Recommendations',
                '24/7 Account Manager Access'
            ]
        }
    },
    digitalMarketingAndDesign: {
        personal: {
            package: 'Starter',
            price: '$200/Month',
            description: 'Coherent and concise branding is the key to rapid social media growth, let us help you develop your brand and messaging!',
            services: [
                'Personal Brand Development',
                'Logo and Content Creation',
                'Social Media Growth Hacking', 
                'Mentorship from dedicated Mentor',
                'Access to exclusive network of local influencers',
                'Weekly short-form posts',
                'Access to local media agencies'
            ]
        },
        local: {
            package: 'Basic',
            price: '$475/Month',
            description: 'Are you an established business but just can’t break into the digital space in a meaningful way? Don’t worry, we got you!',
            services: [
                'Everything in Personal',
                'Dedicated Social Management',
                'Twitter, LinkedIn, Instagram, and Facebook organic management',
                'Paid Ad Management and Creation (LinkedIn, Instagram, Facebook)',
                'Content Creation: Copy and Visual',
                'Technical Social Media Audits',
                'Copy Suggestions',
                'Competitor Analysis',
                'Source Sponsorships',
                'Contract Negotiations'
            ]
        },
        national: {
            package: 'Premium',
            price: '$2750/Month',
            description: 'If you are an influencer or a brand looking to build a national reputation, there isn’t a better team to pick than CMS!',
            services: [
                'Everything in Personal and Local',
                '20 hrs/month of Dedicated Design Team',
                'Visual and Copy Creative Development',
                'Long-form Videos and Custom Images for Social',
                '24/7 Access to Account Management Team',
                '3, 6, and 12 month growth plans',
                'Monthly Content Programming',
                'Relationship Building',
                'Paid Partnership Sourcing'
            ]
        }
    }

}