import React from 'react'
import Layout from '../components/layout'
import { Prices } from '../components/Prices'
import { content } from '../content/content'

export default function PricingPage() {
    return (
        <Layout>
            <div id='pricing_page'>
                <img id='pricing_banner--img' alt='pricingandpackages.png' src='https://i.imgur.com/oUXQ1Yn.png'></img>
                <div id='pricing_webdesign' className='pricing_container--title'>
                    <img className='pricing--img' alt='pricingwebdesingimg.png' src='https://i.imgur.com/v9j0kLl.png'></img>
                    <h3 className='pricing--title'>Web Design</h3>
                </div>
                <div className='price_container'>
                    <Prices package={content.PricesWebDesign.basic.package} price={content.PricesWebDesign.basic.price} subscription={content.PricesWebDesign.basic.subscription} description={content.PricesWebDesign.basic.description} services={content.PricesWebDesign.basic.services} ></Prices>
                    <Prices package={content.PricesWebDesign.intermediate.package} price={content.PricesWebDesign.intermediate.price} subscription={content.PricesWebDesign.intermediate.subscription} description={content.PricesWebDesign.intermediate.description} services={content.PricesWebDesign.intermediate.services}></Prices>
                    <Prices package={content.PricesWebDesign.premium.package} price={content.PricesWebDesign.premium.price} subscription={content.PricesWebDesign.premium.subscription} description={content.PricesWebDesign.premium.description} services={content.PricesWebDesign.premium.services}></Prices>
                </div>
                <div className='pricing_container--title'>
                    <img className='pricing--img' alt='pricingSEO.png' src='https://i.imgur.com/J4WA3Rj.png'></img>
                    <h3 className='pricing--title'>SEO</h3>
                </div>
                <div className='price_container'>
                    <Prices package={content.SEO.basic.package} price={content.SEO.basic.package.price} description={content.SEO.basic.description} services={content.SEO.basic.services}></Prices>
                    <Prices package={content.SEO.intermediate.package} price={content.SEO.intermediate.price} description={content.SEO.intermediate.description} services={content.SEO.intermediate.services}></Prices>
                    <Prices package={content.SEO.premium.package} price={content.SEO.premium.price} description={content.SEO.premium.description} services={content.SEO.premium.services}></Prices>
                </div>
                <div className='pricing_container--title'>
                    <img className='pricing--img' alt='pricingdigitalmarketing.png' src='https://i.imgur.com/zYgne85.png'></img>
                    <h3 className='pricing--title'>Digital Marketing</h3>
                </div>
                <div className='price_container'>
                    <Prices package={content.digitalMarketingAndDesign.personal.package} price={content.digitalMarketingAndDesign.personal.price} description={content.digitalMarketingAndDesign.personal.description} services={content.digitalMarketingAndDesign.personal.services}></Prices>
                    <Prices package={content.digitalMarketingAndDesign.local.package} price={content.digitalMarketingAndDesign.local.price} description={content.digitalMarketingAndDesign.local.description} services={content.digitalMarketingAndDesign.local.services}></Prices>
                    <Prices package={content.digitalMarketingAndDesign.national.package} price={content.digitalMarketingAndDesign.national.price} description={content.digitalMarketingAndDesign.national.description} services={content.digitalMarketingAndDesign.national.services}></Prices>
                </div>
            </div>
        </Layout>
    )
}